.menu-button{
 
    padding: 5px 12px;
    gap: 10px;
    border-radius: 8px;
  }
  .menu-button-hover{
    background-color: #28333F;
    color:white;
    
  }
  .nav-title {
    color: #687D95;
  }
  
  .nav-title:hover {
    color: #fff;
  }
  
  .title-div{
    font-family: 'Plus Jakarta Sans';
    font-size: 18px;
  }

  .redirect-header{
    color: rgb(1, 192, 252);
    font-size: 1.4em;
    text-transform: uppercase;
    font-family: Archivo, sans-serif;
  }
   .redirect-desc{
    font-family: Archivo, sans-serif;
    font-size: 1em;
   }
   
  @media (max-width: 768px) {
    .title-div{
      font-size: 1rem;
    }
  }
  
  @media (max-width: 768px) {
    .menu-button{
      margin: 0px 0px 20px 0px;
    }
  }
  
  .modal-container{
    background: rgb(26, 35, 43) !important;
  }
  .input-div{
    background: transparent;
  
    padding: 1rem 1rem 0rem 1rem;
    border-radius:1rem;
  }
  .input-box-modal{
    background: #1A232B;
  border-radius: 6px;
  padding: 12px;
  }
  .header-modal{
    font-family: 'Open Sans', sans-serif;
  }
  .launch-text{
      background-image: linear-gradient(90.09deg,#01FCFC,#0085FF);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      color: transparent;
  }
  
   
  .hero-section{
    height: 992px;
  }
  
  .second-section-card{
    width: 230px;
    height: 157px;
    padding: 20px 19px 30px 17px;
  }
  .second-section-card p{
    font-style: normal;
    font-weight: 500;
    font-size: 21px;
    line-height: 30px;
    letter-spacing: -0.3px;
    font-feature-settings: 'ss03' on, 'ss04' on, 'ss02' on;
    color: #FFFFFF;
  }
  
  .third-section-card{
    width: 320px;
    height: 168px;
    padding: 20px 19px 30px 17px;
  }
  .third-section-card p{
    font-style: normal;
    font-weight: 500;
    font-size: 21px;
    line-height: 30px;
    letter-spacing: -0.3px;
    font-feature-settings: 'ss03' on, 'ss04' on, 'ss02' on;
    color: #FFFFFF;
    user-select: none;
  }
  .third-section-main{
    margin-top: -14rem;
  }
  .lender-img{
  height: 267.58px;
  margin-top: 15px;;
  }
  .lender-section{
  height: 504px;
  }
  
  .borrower-image{
    width: 447.99px;
    height: 447px;
  }
  @media (max-width: 1536px) {
    .lender-img{
      height: 201.58px;
      margin-top: 3px;
      }
      .lender-section{
        height: 375px;
        }
  
    .borrower-image{
      width: 399px;
      height: 398.12px;
    }
    .hero-section-desc-img{
    width: 28px;
    }
    .second-section-card{
      width: 192px;
      height: 120px;
    }
    .second-section-card p{
      font-size: 18px;
      line-height: 24px;
    }
  .second-section-main{
    padding-top: 3rem;;
  }
    
    .third-section-main{
      margin-top: -12rem;
    }
    .third-section-card{
      width: 292px;
      height: 120px;
    }
    .third-section-card p{
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 24px;
      letter-spacing: -0.3px;
      font-feature-settings: 'ss03' on, 'ss04' on, 'ss02' on;
      color: #FFFFFF;
      user-select: none;
    }
  }
  @media (max-width: 769px) {
    .second-section-card{
      width: 100%;
      text-align: center;
    }
    .borrower-image{
      width: 100%;
      height: auto;
    }
    .third-section-main{
      margin-top: 1rem;
    }
    .third-section-card{
      width: 339px;
      height: 96px;
    }
    .third-section-card p ,.third-section-card img {
     align-self: center;
    }
  }
  