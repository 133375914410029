.rs-toggle-sm.rs-toggle-checked .rs-toggle-presentation:after {
  background: linear-gradient(0deg, #8eda87, #8eda87), #161d23;
}
.rs-toggle-checked .rs-toggle-presentation {
  background: #334150;
}

.rs-toggle-checked .rs-toggle-presentation:hover {
  background: #334150;
}
.rs-toggle-sm .rs-toggle-presentation:after {
  background: #3d4f61;
}
.rs-toggle-sm .rs-toggle-presentation {
  background: #28333f;
}
.guide-sectiion {
  bottom: 1rem;
  left: 35px;
}
.restart-section {
  cursor: pointer;
  bottom: 2.5rem;
  left: 35px;
  font-family: "Space Grotesk";
  font-style: normal;
  font-weight: 700;
  font-size: 11px;
  line-height: 30px;
  /* identical to box height, or 273% */

  display: flex;
  align-items: center;
  letter-spacing: 0.3px;
  text-transform: uppercase;
  font-feature-settings: "ss02" on, "ss03" on, "ss04" on;

  /* backgrounds/G3 */

  color: #687d95;
}
.over-mask {
    z-index: 9999999;
}
[data-tour-elem~="badge"] {
  bottom: 32px;
  left: 20px !important;
  top: auto !important;
  right: auto !important;
  letter-spacing: 0.3px;
  text-transform: uppercase;
  background: none !important;
  box-shadow: none !important;
  color: #687d95 !important;
  font-weight: 500 !important;
  font-size: 13px !important;
  line-height: 30px !important;
  font-family: "Space Grotesk";
  font-style: normal;
}
.mask_tour {
  height: 0px;
}
.helper {
  background-color: #28333f !important;
  /* Drop Shadow */
  font-family: "Space Grotesk";
  font-style: normal;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.3);
  border-radius: 8px !important;
  padding: 12px 20px 20px;
  color: #ffffff !important;
  width: 288px;
  height: 165px;
}
.helper .title-tour {
  font-family: "Space Grotesk";
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  line-height: 30px;
  /* identical to box height, or 231% */

  display: flex;
  align-items: center;
  letter-spacing: 0.3px;
  text-transform: uppercase;
  font-feature-settings: "ss02" on, "ss03" on, "ss04" on;
  position: absolute;
  top: 12px;
  /* primary/semi green-blue */

  color: #01c0fc;
}
[data-tour-elem="controls"] {
  position: absolute;
  bottom: 24px;
  right: 20px;
}
.desc-tour {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  margin-top: 20px;
}
[data-tour-elem="left-arrow"] {
  margin-right: 12px !important;
}
[data-tour-elem="right-arrow"] {
  margin-left: 0px !important;
}
