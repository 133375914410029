@tailwind base;
@tailwind components;
@tailwind utilities;

a:focus, a:hover {
color:white;
}

.guide-header{
    font-family: 'Space Grotesk';
    font-style: normal;
    font-weight: 700;
    font-size: 13px;
    letter-spacing: 0.3px;
    text-transform: uppercase;
    font-feature-settings: 'ss02' on, 'ss03' on, 'ss04' on;
    color: #01C0FC;
}

.guide-text{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    color: #FFFFFF;

}

.react-joyride__tooltip button {  

display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
padding: 9px 14px; 
background: #01C0FC !important;
border-radius: 6px;
}

.react-joyride__tooltip button span{
    font-family: 'Space Grotesk';
    font-style: normal;
    font-weight: 700;
    font-size: 13px;
    line-height: 15px;
    text-align: center;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    font-feature-settings: 'ss02' on, 'ss03' on, 'ss04' on;    
    color: #12181F;

}  
