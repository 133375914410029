.list-header{
    font-family: 'Space Grotesk';
    font-style: normal;
    font-weight: 700;
    font-size: 13px;
    line-height: 30px;
    display: flex;
    align-items: center;
    letter-spacing: -0.3px;
    font-feature-settings: 'ss02' on, 'ss03' on, 'ss04' on;
    color: #687D95;
}
.list-menu{
    margin-top: 1rem;
}