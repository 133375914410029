.modal-heading{
    font-family: 'Space Grotesk';
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    line-height: 30px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.3px;
    text-transform: uppercase;
    font-feature-settings: 'ss02' on, 'ss03' on, 'ss04' on;
    color: #FFFFFF;
}

.email-heading{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 20px;
    display: flex;
    align-items: center;
    color: #FFFFFF;
}

.otp-header{
    font-family: 'Space Grotesk';
    font-style: normal;
    font-weight: 700;
    font-size: 11px;
    line-height: 30px;
    letter-spacing: 0.3px;
    text-transform: uppercase;
    font-feature-settings: 'ss02' on, 'ss03' on, 'ss04' on;
    color: #3D4F61;

}
.get-code, .resend{
    font-family: 'Inter';
font-style: normal;
font-weight: 500;
font-size: 13px;
line-height: 20px;
/* or 150% */

display: flex;
align-items: center;

/* White */

color: #FFFFFF;

}
.resend{
    color:#01C0FC;
    cursor: pointer;
}