.rs-tooltip {

    background: linear-gradient(180.32deg, rgba(34, 151, 241, 0.2) 0.31%, rgba(1, 252, 252, 0.2) 184.05%), #161D23;
    max-width: 500px;
    text-align: left;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 20px;
    color: #FFFFFF;
    padding: 12px 20px;
    border-radius: 8px;

   }
   .rs-tooltip a {
  color:#01C0FC;
  cursor: pointer;
   }

   .add-collateral{
    font-family: 'Space Grotesk';
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 30px;
    letter-spacing: 0.3px;
    text-transform: uppercase;
    font-feature-settings: 'ss02' on, 'ss03' on, 'ss04' on;
    color: #01C0FC;

   }